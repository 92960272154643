.upload-data-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 30px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #f9fafc;
  }
  
  .upload-header {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .upload-header h2 {
    font-size: 28px;
    margin-bottom: 8px;
    color: #2c3e50;
  }
  
  .upload-header p {
    font-size: 16px;
    color: #7f8c8d;
  }
  
  /* Tab Section */
  .upload-tabs {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 30px;
  }
  
  .upload-tab-button {
    padding: 10px 20px;
    background-color: #ecf0f1;
    border: none;
    border-radius: 5px;
    font-weight: 600;
    color: #34495e;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .upload-tab-button.active {
    background-color: #3498db;
    color: #fff;
  }
  
  .upload-tab-button:hover {
    background-color: #2980b9;
    color: #fff;
  }
  
  /* Section Layout */
  .upload-sections {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 30px;
  }
  
  /* Upload Cards */
  .upload-card {
    flex: 1 1 calc(50% - 30px);
    background-color: #ffffff;
    padding: 25px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
    transition: transform 0.2s ease;
  }
  
  .upload-card:hover {
    transform: translateY(-2px);
  }
  
  .upload-card h3 {
    margin-bottom: 12px;
    font-size: 20px;
    color: #2c3e50;
    display: flex;
    align-items: center;
  }
  
  .upload-card h3 svg {
    margin-right: 8px;
  }
  
  .upload-card p {
    font-size: 15px;
    color: #7f8c8d;
    margin-bottom: 20px;
  }
  
  .upload-card input[type="file"] {
    font-size: 14px;
  }
  
  .upload-button {
    background-color: #3498db;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
    margin-top: 15px;
  }
  
  .upload-button:hover {
    background-color: #2980b9;
  }
  
  .disabled-button {
    background-color: #bdc3c7;
    cursor: not-allowed;
  }
  
  .upload-disabled p {
    color: #bdc3c7;
  }
  
  /* Responsive */
  @media (max-width: 768px) {
    .upload-card {
      flex: 1 1 100%;
    }
  
    .upload-tabs {
      flex-direction: column;
      align-items: center;
    }
  }