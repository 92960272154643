/* 🔷 Base Layout */
.data-validation-wrapper {
  display: flex;
  flex-direction: column;
  padding: 20px;
  font-family: "Segoe UI", sans-serif;
}

.header-row {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.llm-button {
  background-color: #1976d2;
  color: white;
  padding: 10px 16px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: bold;
}

.data-validation-container {
  display: flex;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
}

/* 🔶 Left Panel - Resident Folders */
.resident-list {
  width: 35%; /* increased from 30% to give room */
  border-right: 1px solid #ccc;
  padding: 16px;
  background: #f8f9fa;
  overflow-y: auto;
}

.folder-name {
  white-space: normal;  /* 👈 allow full wrapping */
  word-break: break-word;
  max-width: 100%;      /* fully use list item space */
}

.resident-list h3 {
  margin-bottom: 10px;
}

.resident-folder {
  padding: 8px 10px;
  border-radius: 4px;
  margin-bottom: 6px;
  cursor: pointer;
}

.resident-folder.selected {
  background-color: #e3f2fd;
}

.resident-folder:hover {
  background-color: #e0f0fc;
}

.folder-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Status & Spinner */
.status-tag {
  padding: 3px 8px;
  border-radius: 12px;
  font-size: 12px;
  text-transform: uppercase;
}

.status-completed {
  background-color: #c8e6c9;
  color: #256029;
}

.status-failed {
  background-color: #ffcdd2;
  color: #c62828;
}

.spinner {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #1976d2;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* 🔷 Global Progress */
.progress-container {
  background-color: #eee;
  border-radius: 6px;
  margin: 10px 0;
  height: 10px;
  position: relative;
  overflow: hidden;
}

.progress-bar {
  background-color: #1976d2;
  height: 100%;
  transition: width 0.4s ease;
}

.progress-text {
  font-size: 12px;
  margin-top: 4px;
  text-align: right;
  color: #333;
}

.completion-message {
  background-color: #e8f5e9;
  color: #2e7d32;
  font-size: 14px;
  padding: 8px 10px;
  border-radius: 4px;
  margin-bottom: 10px;
  font-weight: 500;
}

/* 🔷 Right Panel */
.right-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 16px;
}

.document-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.document-card {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: border 0.2s ease;
}

.document-card:hover {
  border-color: #aaa;
}

.document-card.selected {
  border: 2px solid #1976d2;
}

.document-icon {
  margin-right: 8px;
  color: #555;
}


.right-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 16px;
  height: 80vh; /* ⬅️ give a total height */
}

.document-list {
  flex: 0 0 20%;
  margin-bottom: 8px;
  overflow-y: auto;
}

.document-text {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.document-text textarea {
  flex: 1;
  width: 100%;
  resize: vertical;
  padding: 10px;
  font-family: monospace;
  font-size: 14px;
  border-radius: 6px;
  border: 1px solid #ccc;
}