.staffing-template-wrapper {
    padding: 20px;
    font-family: "Segoe UI", sans-serif;
  }
  
  .staffing-template-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .template-actions {
    display: flex;
    gap: 10px;
  }
  
  .template-select,
  .save-button {
    padding: 8px 12px;
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid #ccc;
  }
  
  .save-button {
    margin-top: 10px;
    background-color: #1976d2;
    color: white;
    font-weight: bold;
    cursor: pointer;
  }
  
  .matrix-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .matrix-table th,
  .matrix-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  .matrix-table th.risk-header,
  .matrix-table td.risk-header {
    text-align: left;
    background-color: #f4f4f4;
    font-weight: bold;
    width: 200px;
  }
  
  .select-cell select {
    width: 100px;
    margin: 2px 0;
  }
  
  .risk-label {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .risk-icon {
    color: #1976d2;
    font-size: 16px;
  }
  
  /* Modal Styling */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    max-width: 90%;
  }
  
  .modal-content h3 {
    margin-bottom: 10px;
  }
  
  .modal-content input,
  .modal-content select {
    width: 100%;
    padding: 8px;
    margin: 8px 0;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .modal-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
  
  .modal-buttons button {
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .modal-buttons button:first-child {
    background-color: #1976d2;
    color: white;
  }
  
  .modal-buttons button:last-child {
    background-color: #ccc;
  }