/* HAMBURGER MENU (Now Only in Header) */
.menu-toggle {
  position: absolute;
  left: 15px; /* Moves burger to the left */
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 28px;
  cursor: pointer;
  color: white;
  z-index: 1100; /* Higher than menu */
}

/* SIDEBAR MENU */
.menu-slide {
  position: fixed;
  top: 60px; /* Below header */
  left: -250px;
  width: 250px;
  height: calc(100% - 60px);
  background: #34495e;
  padding: 20px;
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.3);
  transition: left 0.3s ease-in-out;
  z-index: 1000;
}

/* MENU OPEN STATE */
.menu-slide.open {
  left: 0;
}