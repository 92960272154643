/* Overview.css */

.overview-container {
    width: 100%;
    max-width: 1200px;
    margin: auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
    text-align: center;
  }
  
  h2 {
    color: #333;
    margin-bottom: 20px;
  }
  
  .chart-container {
    background: white;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  h3 {
    color: #007bff;
    margin-bottom: 10px;
  }
  