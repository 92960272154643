.resident-overview-container {
    padding: 20px;
    text-align: center;
  }
  
  /* Step Navigation */
  .step-navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100%;
    margin: 20px auto;
  }
  
  /* Progress Line BELOW Icons */
  .progress-line {
    position: absolute;
    bottom: -10px; /* Adjusted to ensure spacing below icons */
    left: 0; /* Ensures full width */
    width: 100%; /* Stretches across the container */
    height: 4px;
    background: #ddd;
    z-index: -1; /* Ensures it stays behind the icons */
  }
  
  /* Progress Fill */
  .progress-fill {
    height: 100%;
    background: #3498db;
    transition: width 0.3s ease-in-out;
  }
  
  /* Individual Steps */
  .step {
    text-align: center;
    position: relative;
    flex: 1;
    text-decoration: none;
    color: #888;
    transition: all 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  /* Step Icon */
  .step-icon {
    font-size: 24px;
    margin-bottom: 5px;
    background: white;
    padding: 10px;
    border-radius: 50%;
    z-index: 2; /* Ensures icon stays on top */
  }
  
  /* Active Steps */
  .step.active {
    color: #3498db;
    font-weight: bold;
  }
  
  .step.active .step-icon {
    color: #3498db;
    transform: scale(1.2);
  }