.risk-summary-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .risk-summary-table th,
  .risk-summary-table td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: center;
  }
  
  .risk-summary-table th {
    font-weight: bold;
  }
  
  .category-header {
    background-color: #f4f4f4;
  }
  
  /* Alternate color bands for categories */
  .category-0 {
    background-color: #e1f5fe;
  }
  
  .category-1 {
    background-color: #fce4ec;
  }
  
  .category-2 {
    background-color: #e8f5e9;
  }
  
  .category-3 {
    background-color: #fff3e0;
  }
  
  .category-4 {
    background-color: #ede7f6;
  }
  
  .critical-score {
    background-color: #ffebee;
    color: #c62828;
    font-weight: bold;
  }

  .resident-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .resident-modal {
    background-color: white;
    padding: 20px;
    border-radius: 12px;
    width: 80%;
    max-width: 800px;
    max-height: 90%;
    overflow-y: auto;
    position: relative;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 18px;
    background: none;
    border: none;
    cursor: pointer;
    color: #333;
  }
  
  .chart-container {
    margin-top: 20px;
    height: 400px;
  }

  .unit-header-row td {
    text-align: left !important; /* 👈 force left alignment */
    padding-left: 12px;
  }
  
  .unit-header-content {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .unit-toggle {
    font-weight: bold;
    font-size: 18px;
    cursor: pointer;
  }
  
  .unit-label {
    font-weight: bold;
  }