.staffing-model-container {
  padding: 20px;
}

.staffing-form {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.staffing-form select,
.staffing-form button {
  padding: 6px 10px;
  font-size: 14px;
}

.staffing-form input[type="checkbox"] {
  margin-left: 10px;
}

.chart-wrapper {
  margin-bottom: 32px;
  background: #f9f9f9;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.05);
}

.table-wrapper {
  overflow-x: auto;
  margin-bottom: 40px;
}

.staffing-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.staffing-table th,
.staffing-table td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

.staffing-table th {
  background-color: #f0f0f0;
  font-weight: bold;
}