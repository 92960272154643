.summarization-container {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .summary-cards {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .card {
    background-color: #f0f0f0;
    padding: 14px 18px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
  }
  
  .chart-section {
    margin-bottom: 40px;
  }
  
  .dropdown-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
  }
  
  .filters {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .filters input[type="text"] {
    padding: 6px 10px;
    font-size: 14px;
    width: 300px;
  }
  
  .print-button {
    padding: 6px 12px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .resident-table-section {
    overflow-x: auto;
    margin-bottom: 40px;
  }
  
  .resident-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
  }
  
  .resident-table th,
  .resident-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  .resident-table th {
    background-color: #f0f0f0;
  }
  
  .critical-score {
    background-color: #fdd;
    font-weight: bold;
  }
  
  .download-button {
    padding: 4px 8px;
    background-color: #1976d2;
    color: white;
    text-decoration: none;
    border-radius: 4px;
    font-size: 13px;
  }
  
  .chart-section-flex {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    margin-bottom: 40px;
    flex-wrap: wrap;
  }
  
  .chart-box {
    flex: 1 1 48%;
    background-color: #fafafa;
    border: 1px solid #ddd;
    padding: 12px;
    border-radius: 8px;
    box-sizing: border-box;
  }
  
  .chart-box h3 {
    margin-bottom: 10px;
    font-size: 16px;
  }
  
  .chart-section h3 {
    margin-bottom: 16px;
    text-align: center;
  }

  @media screen and (max-width: 768px) {
    .chart-box {
      flex: 1 1 100%;
    }
  }
  
  @media print {
    .print-button,
    .download-button,
    .filters,
    .dropdown-wrapper,
    .chart-section,
    .summary-cards {
      display: none;
    }
  
    .summarization-container {
      padding: 0;
    }
  }