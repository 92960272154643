.login-stats {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 30px;
    gap: 20px;
    background-color: #f5f7fa;
    border-top: 1px solid #e0e0e0;
  }
  
  .chart-block {
    flex: 1 1 250px;
    max-width: 300px;
    background: #fff;
    border-radius: 12px;
    padding: 16px;
    box-shadow: 0 4px 10px rgba(0,0,0,0.07);
    transition: transform 0.2s ease-in-out;
  }
  
  .chart-block:hover {
    transform: scale(1.02);
  }
  
  .chart-block h4 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
    color: #333;
  }