/* ===== Main Container ===== */
.login-page-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #f9fafc;
  }
  
  /* ===== Main Content Layout ===== */
  .login-main-content {
    width: 100%;
    max-width: 1200px;
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  /* ===== Logo Section ===== */
  .branding {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .branding .company-logo {
    width: 300px;
    max-width: 100%;
    height: auto;
  }
  
  /* ===== Login Box ===== */
  .login-box-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }
  
  .login-box {
    width: 100%;
    max-width: 350px;
    background-color: #fff;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  }
  
  .login-box h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 22px;
  }
  
  .login-box input {
    width: 100%;
    padding: 12px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 6px;
    font-size: 14px;
  }
  
  .login-box button {
    width: 100%;
    padding: 12px;
    background-color: #6a8caf;
    color: white;
    border: none;
    border-radius: 6px;
    font-size: 16px;
    margin-top: 10px;
    cursor: pointer;
  }
  
  .login-box button:hover {
    background-color: #4c6f95;
  }
  
  /* ===== Chart Section (LoginStats.js) ===== */
  .login-stats {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px 10px;
    gap: 20px;
    margin-bottom: 30px;
  }
  
  .chart-block {
    flex: 1 1 250px;
    max-width: 300px;
    background: #fff;
    border-radius: 12px;
    padding: 16px;
    box-shadow: 0 4px 10px rgba(0,0,0,0.07);
    transition: transform 0.2s ease-in-out;
  }
  
  .chart-block:hover {
    transform: scale(1.02);
  }
  
  .chart-block h4 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
    color: #333;
  }
  
  /* ===== Product Section ===== */
  .product-section {
    margin-top: 10px;
    text-align: center;
    padding-bottom: 40px;
  }
  
  .product-section h2 {
    font-size: 20px;
    margin-bottom: 20px;
    color: #444;
  }
  
  .product-grid {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .product-block {
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 260px;
    max-width: 90%;
    text-align: center;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.06);
    transition: all 0.2s ease;
    text-decoration: none;
    color: inherit;
  }
  
  .product-block:hover {
    transform: translateY(-5px);
  }
  
  .product-block img {
    width: 100%;
    max-width: 100%;
    height: auto;
    object-fit: contain;
    margin-bottom: 15px;
  }
  
  .product-block h3 {
    font-size: 18px;
    margin: 10px 0;
  }
  
  /* ===== Responsive Styling ===== */
  @media (max-width: 900px) {
    .login-main-content {
      padding: 20px 15px;
    }
  
    .branding .company-logo {
      width: 200px;
    }
  
    .login-box-wrapper {
      margin-bottom: 20px;
    }
  
    .login-box {
      width: 90%;
      max-width: 360px;
    }
  
    .product-grid {
      flex-direction: column;
      align-items: center;
    }
  
    .product-block {
      width: 90%;
      max-width: 320px;
    }
  
    .chart-block {
      max-width: 100%;
      width: 90%;
    }
  }