.current-uploads-container {
    padding: 20px;
  }
  
  .upload-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .upload-table th,
  .upload-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
  }
  
  .upload-table th {
    background-color: #f4f4f4;
  }
  
  .upload-row:hover {
    background-color: #f9f9f9;
    cursor: pointer;
  }
  
  .upload-row.selected {
    background-color: #d1e7fd;
  }
  
  .status {
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 5px;
  }
  
  .status.in_progress {
    color: orange;
  }
  
  .status.complete {
    color: green;
  }
  
  .status.failed {
    color: red;
  }
  
  .next-button {
    display: block;
    margin: 20px auto;
    padding: 12px 20px;
    font-size: 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
  }
  
  .next-button:disabled {
    background-color: gray;
    cursor: not-allowed;
  }
  
  .next-button:hover:not(:disabled) {
    background-color: #0056b3;
  }