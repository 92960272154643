/* 🌟 Modern Upload Page Styles */
.upload-container {
    width: 90%;
    max-width: 1200px;
    margin: 30px auto;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    overflow: hidden;
  }
  
  /* 📌 Tab Navigation */
  .upload-tabs {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: #f8f9fa;
    border-bottom: 2px solid #ddd;
    border-radius: 8px 8px 0 0;
    padding: 10px;
  }
  
  .upload-tab {
    flex: 1;
    padding: 12px 15px;
    font-size: 16px;
    font-weight: 500;
    color: #555;
    background: transparent;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    position: relative;
  }
  
  .upload-tab:hover {
    color: #007bff;
  }
  
  /* ✅ Active Tab */
  .upload-tab.active {
    font-weight: 600;
    color: #007bff;
  }
  
  /* 🔹 Underline Indicator */
  .upload-tab-indicator {
    height: 4px;
    width: 25%;
    background: #007bff;
    transition: transform 0.3s ease-in-out;
  }
  
  /* ✅ Move indicator based on active tab */
  .upload-tab-indicator.current { transform: translateX(0%); }
  .upload-tab-indicator.local { transform: translateX(100%); }
  .upload-tab-indicator.s3 { transform: translateX(200%); }
  .upload-tab-indicator.api { transform: translateX(300%); }
  
  /* 📌 Content Area */
  .upload-content {
    padding: 20px;
    min-height: 400px;
    background: #fff;
    border-radius: 0 0 12px 12px;
  }