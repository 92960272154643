/* HAMBURGER MENU (Before Facility Name, Properly Positioned) */
.menu-toggle {
  position: absolute;
  left: 10px;  /* Moves burger further left */
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 28px;
  cursor: pointer;
  color: white;
  z-index: 1100; /* Higher than menu */
}

/* DASHBOARD HEADER (Ensures Facility Name is Visible) */
.dashboard-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;  /* Aligns text and menu properly */
  padding-left: 50px;  /* Creates space for burger icon */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background: #2c3e50;
  color: white;
  z-index: 1000;
}

/* LOGOUT BUTTON */
.logout-button {
  background: red;
  color: white;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  font-size: 14px;
  border-radius: 5px;
  position: absolute;
  right: 45px; /* Keeps it within screen limits */
  top: 50%;
  transform: translateY(-50%);
}

/* MENU WRAPPER - Keeps the Menu Below Header */
.menu-wrapper {
  margin-top: 60px; /* Ensures menu starts below header */
}

/* Menu Open - Changes to 'X' */
.menu-toggle.open {
  content: '✖'; /* Changes icon when menu is open */
}

/* SIDEBAR MENU */
.menu-slide {
  position: fixed;
  top: 60px; /* Ensures menu starts below header */
  left: -250px;
  width: 250px;
  height: calc(100% - 60px);
  background: #34495e;
  padding: 20px;
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.3);
  transition: left 0.3s ease-in-out;
}

/* MENU OPEN STATE */
.menu-slide.open {
  left: 0;
}

/* CLOSE BUTTON (Only Visible When Menu is Open) */
.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  float: right;
  color: white;
  display: none;
}

/* Show Close Button Only When Menu is Open */
.menu-slide.open .close-button {
  display: block;
}

/* MENU ITEMS */
.menu-slide ul {
  list-style: none;
  padding: 0;
}

.menu-slide li {
  padding: 10px;
}

.menu-slide a {
  color: white;
  text-decoration: none;
  font-size: 18px;
}

.menu-slide a:hover {
  color: #f39c12;
}

/* MAIN CONTENT */
.dashboard-main {
  margin-top: 60px;
  padding: 20px;
}